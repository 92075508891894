<template>
    <div class="pb-4">
        <table>
            <tbody>
                <tr v-for="(row, index) in cardRows" :key="keyName + index" :class="{ 'font-bold': lastRow(cardRows, index) }">
                    <td width="350">
                        <p>{{ row.description }}</p>
                    </td>
                    <td width="150">
                        <p class="flex justify-end">{{ row.currentYear }}</p>
                    </td>
                    <td width="150">
                        <p class="flex justify-end">{{ row.previousYear }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        cardRows: {
            type: Array,
            default: () => [],
        },
        keyName: {
            type: String,
            default: "",
        },
    },
    methods: {
        lastRow(row, index) {
            return row.length - 1 === index;
        },
    },
};
</script>
